.app-bar-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-evenly;
}

.app-bar-title-text {
  display: flex;
  justify-content: center;
  padding: 1.5%;
  font-family: proxima-nova;
  font-weight: 600;
  font-style: normal;
  line-height: 2em;
  /* font-size: 15px; */
  font-size: 1vw;
  text-transform: uppercase;
  letter-spacing: 2.75px;
  color: rgba(0, 0, 0, 0.52);
  text-align: center;
}

.app-bar-title-text-mobile {
  display: flex;
  justify-content: center;
  padding: 1.5%;
  font-family: proxima-nova;
  font-weight: 600;
  font-style: normal;
  line-height: 2em;
  /* font-size: 15px; */
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 2.75px;
  color: rgba(0, 0, 0, 0.52);
  text-align: center;
}

.app-bar-logo {
  width: 100%;
  padding-top: 0%;
  padding-right: 5%;
  padding-left: 5%;
  display: "flex";
  justify-content: center;
}

.app-bar-logo-mobile {
  width: 100%;
  display: "flex";
  justify-content: center;
}

.app-bar-nav-bar {
  width: 100%;
  display: "flex";
  flex: auto;
  justify-content: center;
  align-content: center;
}

.open-menu {
  display: flex;
  flex-direction: column;
}

.menu-button {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
