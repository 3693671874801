.welcome-image {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-right: 5%;
  padding-left: 5%;
  max-height: 100%;
  max-width: 100%;
}

.welcome-image-container-text {
  position: relative;
  text-align: center;
  color: white;
}

.welcome-centered-image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: proxima-nova;
  font-size: xx-large;
  transform: translate(-50%, -50%);
  letter-spacing: 3px;
}

.welcome-centered-image-underline {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: proxima-nova;
  font-size: xx-large;
  padding-top: 1%;
  transform: translate(-50%, -50%);
  letter-spacing: 3px;
}


.welcome-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.welcome-bulleted-list {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}

.welcome-link-text {
  display: flex;
  justify-content: center;
  align-content: center;
  color: black !important;
  font-weight: bold;
  padding-bottom: 1%;
  font-style: italic;
  text-align: center;

}

.welcome-testimonials-container {
  display: flex;
  justify-content: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-left: 5%;
  padding-right: 5%;
}
