.faq-master-div {
  padding-top: 2.5%;
}

.faq-div-css {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

.faq-text {
  display: flex;
  flex-grow: 1;
}

.faq-grow {
  display: flex;
  text-align: center;
  flex-basis: 25%;
}

.faq-shrink {
  display: flex;
  flex-basis: 75%;
}

.faq-image {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-right: 5%;
  padding-left: 5%;
  max-height: 100%;
  max-width: 100%;
}

.faq-link-text {
  display: flex;
  justify-content: center;
  color: black !important;
  font-weight: bold;
  padding-bottom: 1%;
  font-style: italic;
  text-align: center;
}

.faq-div-mobile{
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

.faq-text-mobile{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  text-align: center;
}
