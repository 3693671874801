.reiki-master-div {
  padding-top: 2.5%;
}

.reiki-title-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
}

.reiki-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
  }

.reiki-divider-reducer {
  padding-left: 25%;
  padding-right: 25%;
}

.reiki-link-text {
  display: flex;
  justify-content: center;
  color: black !important;
  font-weight: bold;
  padding-bottom: 15px;
  font-style: italic;
}
