.reach-out-image {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-right: 5%;
  padding-left: 5%;
  max-height: 100%;
  max-width: 100%;
}

.reach-out-image-container-text {
  position: relative;
  text-align: center;
  color: white;
}

.reach-out-centered-image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: proxima-nova;
  font-size: 200%;
  transform: translate(-50%, -50%);
  letter-spacing: 3px;
}

.reach-out-centered-image-underline {
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: proxima-nova;
  font-size: 200%;
  padding-top: 1%;
  transform: translate(-50%, -50%);
  letter-spacing: 3px;
}

.reach-out-column {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-right: 5%;
  padding-left: 5%;
  max-height: 100%;
  max-width: 100%;
}

.reach-out-left {
  width: 50%;
  float: left;
}

.reach-out-right {
  width: 50%;
  float: right;
}

.reach-out-mobile-form{
  
}
