.services-master-div {
  padding-top: 2.5%;
}

.services-div-css {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

.services-reiki {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1.810938;
}

.services-reiki img:hover {
  transition: all 0.2s ease-in-out;
  opacity: 0.75;
}

.services-meditation {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1.499872;
}

.services-meditation img:hover {
  transition: all 0.2s ease-in-out;
  opacity: 0.75;
}

.services-children {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1.626159;
}

.services-children img:hover {
  transition: all 0.2s ease-in-out;
  opacity: 0.75;
}

.services-image {
  display: flex;
  justify-content: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-right: 5%;
  padding-left: 5%;
  height: auto;
  max-width: 100%;
  flex-wrap: wrap;
}

.services-link-text {
  color: black !important;
  font-weight: bold;
}

.services-link-sentance {
  display: flex;
  justify-content: center;
  color: black !important;
  padding-bottom: 15px;
}

.services-div-css-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

.services-link-text-mobile {
  display: flex;
  justify-content: center;
  color: black !important;
  font-weight: bold;
  padding-top: 1%;
  padding-bottom: 15px;
  font-style: italic;
  text-align: center;
}
