.form {
  display: flex;
  flex-direction: column;
}

.form-elements {
  margin-bottom: 2.5%;
}

#popUpYes {
  background-color: black;
  color: white;
  /* SET COLOR IN WHITE */
}

#popUpYes:hover {
  transition: all 0.2s ease-in-out;
  background-color: white;
  color: black;
  /* SET COLOR IN BLACK */
}

.submission-true {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}
