body {
    padding-top: 5%;
    padding-right: 10%;
    padding-bottom: 5%;
    padding-left: 10%; 
}

.canvas{
    border: solid rgba(0,0,0,.1);
    background-color: #f7f6f2;
}