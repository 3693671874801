.training-master-div {
    padding-top: 2.5%;
}

.training-div-padding{
    padding-top: 2.5%;
    padding-bottom: 2.5%;
}

.training-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .training-image {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    padding-right: 5%;
    padding-left: 5%;
    max-height: 100%;
    max-width: 100%;
  }
  
  .training-link-text {
    display: flex;
    justify-content: center;
    color: black !important;
    font-weight: bold;
    padding-top: 1%;
    padding-bottom: 15px;
    font-style: italic;
    text-align: center;
  }
  