.journey-quote-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2.5%;
}

.journey-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2.5%;
  padding-left: 5%;
  padding-right: 5%;
}

.journey-image {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-right: 5%;
  padding-left: 5%;
  max-height: 100%;
  max-width: 100%;
}

.journey-link-text {
  display: flex;
  justify-content: center;
  color: black !important;
  font-weight: bold;
  padding-bottom: 15px;
  font-style: italic;
  text-align: center;
}

.journey-bottom-padding{
    padding-bottom: 2.5%;
}

.journey-bulleted-list {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
}
