.footer-text {
  display: flex;
  padding-top: 2.5%;
  justify-content: center;
}

.footer-div-css {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.footer-div-mobile-css{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
